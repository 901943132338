/* eslint-disable no-param-reassign */
import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  createRef,
} from 'react';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import cn from 'classnames';
import i18next from 'i18next';
import { ReactSVG } from 'react-svg';
import Tooltip from 'components/UI/Tooltip';
import OnlyOwner from 'components/OnlyOwner';
import { FiCloudLightning } from 'react-icons/fi';
import { useUserTheme } from 'domains/user/model/selectors';
import Checkbox from 'components/UI/Checkbox/checkbox';
import get from 'lodash/get';
import throttle from 'lodash/throttle';
import { useIsGroup } from 'domains/user/hooks';
import PointSvg from '../icons/point.svg';
import CopySvg from '../icons/copy.svg';
import EditSvg from '../icons/edit.svg';
import css from './styles.module.scss';
import SortHeader from './SortHeader';
import { numFormat } from '../../../utils/toFixedNumber';
import { TypesTable } from '../../../types/general';
import ItemActions from './ItemActions';
import ArrowSvg from '../item/HierarchicalActions/icons/arrow.svg';
import { PDataGrid, Tdata, TLeftD, TSortType } from '../model/types';
import { contextTarget } from '../model/shared';
import statOffSvg from '../../../assets/stat_off.svg';
import { checkLimitsCellValue } from '../model/utils/checkLimitsCellValue';
import ItemBidType from './ItemBidType';

const MIN_ROWS = 14;
const TABLE_H = 500;

const style_sortHeader = {
  borderRight: '1px solid #ebebeb',
};

const DataGrid: FC<PDataGrid> = ({
  columns,
  total,
  data,
  view,
  leftD,
  search,
  customFilter,
  isEmpty,
  type,
  onChekedLD,
  onRemoveItem,
  use_settings,
  read_only,
  isSourceLikeLogic,
  onChangeContextBox,
  onShowContextBox,
  downloadState,
  showStatistic,
  onlyChecked,
  isError,
  fetchStatistics,
  showPageContextRow,
  showPageAppRow,
  setLeftD,
  checkItemLimits,
  setShowSaveButton,
  statisticsIsGroup,
  showHintEvent,
  onChangeCellData,
  setTouchedItemsToSave,
}) => {
  const { isOwner, isLocked } = useIsGroup();
  const [innerData, setInnerData] = useState<Tdata[]>(() => data);
  const [innerleftD, setInnerleftD] = useState<TLeftD[]>(() => leftD);
  const [allCheck, setAllCheck] = useState<boolean>(false);
  const [anyCheck, setAnyCheck] = useState<boolean>(false);
  const [scrollCalc, setScrollCalc] = useState<number>(0);
  const [someIsOpen, setSomeIsOpen] = useState<boolean>(false);
  const isSourceLike = type === 'source' || type === 'network';
  const fieldsToHide = ['BIDRATE', 'MAXBID'];

  const hideFieldsByRoleAndTitle = (
    role: boolean,
    title: string,
    fields: string[],
  ) => {
    if (!role && fields.includes(title)) {
      return false;
    }
    return true;
  };

  const itemKeyData = (i: number, d: Tdata[]) =>
    type === 'hours_of_day_user' || type === 'hours_of_day'
      ? d[i].key + d[i].isChild + i
      : d[i]?.key + i;
  const itemKeyLeft = (i: number, d: TLeftD[]) =>
    type === 'hours_of_day_user' || type === 'hours_of_day'
      ? d[i].key + d[i].isChild + i
      : d[i]?.key + i;

  const { isThemeFull, isThemeExtended } = useUserTheme();
  const ROW_H = 40;

  const throttledScrollCalc = useCallback(
    throttle((newValue: number) => setScrollCalc(newValue), 500),
    [],
  );

  useEffect(() => {
    setInnerData([...data]);
  }, [data]);
  // проверяем состояние всех чекбоксов таргета для общего чекбокса
  useEffect(() => {
    const checkValArr: ('partially' | 'allCheck' | 'noCheck')[] =
      innerleftD.map((element) => checkPartially(element));
    setAnyCheck(
      checkValArr.some((item) => item === 'partially' || item === 'allCheck') &&
        checkValArr.some((item) => item === 'noCheck'),
    );
    setAllCheck(checkValArr.every((item) => item === 'allCheck'));
  }, [innerleftD, search, onlyChecked]);

  const [expand, setExpand] = useState<number>(
    isThemeFull || isThemeExtended ? 320 : 250,
  );
  const [sortType, setSortType] = useState<TSortType>({
    type: TypesTable.DEFAULT,
    column: '',
  });

  const onSetExpand = (value: number) => {
    setExpand(value);
  };
  // рекурсивные функции фильтрации(поиска) для левой и правой части таргета
  // Сократить
  function filterRight(array: Tdata[], text: string) {
    const getNodes = (result: Tdata[], object: Tdata) => {
      if (object.title.toUpperCase().includes(text.toUpperCase())) {
        result.push(object);
        return result;
      }
      if (Array.isArray(object.children)) {
        const children = object.children.reduce(getNodes, []);
        if (children.length) result.push({ ...object, children });
      }
      return result;
    };

    return array.reduce(getNodes, []);
  }
  // Сократить
  function filterLeft(array: TLeftD[], text: string) {
    const getNodes = (result: TLeftD[], object: TLeftD) => {
      if (object.title.toUpperCase().includes(text.toUpperCase())) {
        result.push(object);
        return result;
      }
      if (Array.isArray(object.children)) {
        const children = object.children.reduce(getNodes, []);
        if (children.length) result.push({ ...object, children });
      }
      return result;
    };

    return array.reduce(getNodes, []);
  }

  const filtering = useMemo(
    () => (s: string) => {
      if (s === '') {
        return {
          leftDF: [...leftD],
          dataF: [...data],
        };
      }
      return {
        leftDF: filterLeft(leftD, s),
        dataF: filterRight(data, s),
      };
    },
    [data, leftD],
  );

  const checkLimitsValue = (item: Tdata, key: string) => {
    const value = get(item, key);
    return value === undefined ? '-' : numFormat(i18next.language, value);
  };

  const sorting = useMemo(
    () => (lD: TLeftD[], d: Tdata[]) => {
      // eslint-disable-next-line no-shadow
      const { type, column } = sortType;
      if (type === TypesTable.DEFAULT) return { dataS: d, leftDS: lD };
      let mapped: { index: number; value: string | number }[];
      if (column === 'title') {
        mapped = lD.map((el, index) => ({
          index,
          value: el.title,
        }));
      } else {
        const col = columns.find((item) => item.title === column);
        mapped = d.map((el, i) => ({
          index: i,
          value: col?.path
            ? checkLimitsCellValue(el, col.path)
            : el[col?.dataIndex ?? ''] || 0,
        }));
      }
      mapped.sort((a, b) => {
        if (a.value > b.value) return type === TypesTable.ASC ? 1 : -1;
        if (a.value < b.value) return type === TypesTable.ASC ? -1 : 1;
        return 0;
      });
      const dataS: Tdata[] = [];
      const leftDS: TLeftD[] = [];
      mapped.forEach((el) => {
        dataS.push(d[el.index]);
        leftDS.push(lD[el.index]);
      });
      return {
        dataS,
        leftDS,
      };
    },
    [sortType],
  );

  useEffect(() => {
    const { leftDF, dataF } = filtering(search);
    let leftCF: TLeftD[] = leftDF;
    let dataCF: Tdata[] = dataF;
    if (typeof customFilter === 'function') {
      const { lDCF, dCF } = customFilter(leftDF, dataF);
      leftCF = lDCF;
      dataCF = dCF;
    }
    const { dataS, leftDS } = sorting(leftCF, dataCF);
    setInnerData(dataS);
    setInnerleftD(leftDS);
  }, [search, sortType, customFilter, data, leftD]);

  const onSetSortType = useCallback((v: TSortType) => {
    setSortType(v);
  }, []);
  // функция заполнения массива на основе которого строится вьюшка левой части таргета
  const leftDataRecursivePush = (
    item: TLeftD,
    dataRight: Tdata[],
    res: TLeftD[],
  ) => {
    res.push(item);
    const innerRes: TLeftD[] = [];
    if (item?.isToggle) {
      if (item.children) {
        const childrenData =
          dataRight.find((itemRight) => itemRight.key === item.key)?.children ||
          [];
        const { leftDS } = sorting(item.children, childrenData);
        leftDS.forEach((child) =>
          leftDataRecursivePush(
            child,
            dataRight.find((itemRight) => itemRight.key === item.key)
              ?.children || [],
            innerRes,
          ),
        );
        res.push(...innerRes);
      }
    }
  };
  // функция для сборки и согласованной сортировки левой части таргета
  const flattenDataLeft = useCallback(
    (dataLeft: TLeftD[], dataRight: Tdata[]) => {
      const res: TLeftD[] = [];
      dataLeft.forEach((item) => {
        leftDataRecursivePush(item, dataRight, res);
      });
      return res;
    },
    [innerleftD, innerData, search],
  );

  const colClass = useMemo(() => {
    const classes = {};
    columns.forEach((col) => {
      const differentSizeWithSmallText = 3;
      const sizeTotalData =
        String(Number(total?.[col.dataIndex]).toFixed(2)).length - 3;

      if (
        col.title.length >= sizeTotalData ||
        col.dataIndex === 'bid_rate' ||
        col.dataIndex === 'bid_start' ||
        col.dataIndex === 'maxbid'
      ) {
        classes[col.dataIndex] = `size-${col.title.length}`;
      } else {
        classes[col.dataIndex] = `size-${
          col.title === 'Bid'
            ? sizeTotalData - differentSizeWithSmallText
            : sizeTotalData
        }`;
      }
    });

    return classes;
  }, [total, columns]);
  // функция заполнения массива на основе которого строится вьюшка правой части таргета
  const rightDataRecursivePush = (
    item: TLeftD,
    index: number,
    dataRight: Tdata[],
    res: Tdata[],
  ) => {
    const innerRes: Tdata[] = [];
    res.push(dataRight[index]);
    if (item?.isToggle) {
      if (item.children) {
        const childrenData =
          dataRight.find((dataR) => dataR.key === item.key)?.children || [];
        const { leftDS, dataS } = sorting(item.children, childrenData);
        leftDS.forEach((child, childIndex) =>
          rightDataRecursivePush(child, childIndex, dataS, innerRes),
        );
        res.push(...innerRes);
      }
    }
  };
  // функция для сборки и согласованной сортировки правой части таргета
  const flattenData = useCallback(
    (left: TLeftD[], dataRight: Tdata[]) => {
      const res: Tdata[] = [];
      left.forEach((item, index) => {
        rightDataRecursivePush(item, index, dataRight, res);
      });
      return res;
    },
    [innerleftD, innerData, search],
  );

  const searchLabel = (title: string, arg: string): JSX.Element | string => {
    if (title.toLowerCase().includes(arg.toLowerCase())) {
      const start = title.toLowerCase().indexOf(arg.toLowerCase());
      const end = start + arg.length;
      return (
        <span>
          <b title={title}>{title.slice(0, start)}</b>
          <span className={css.searchTitle} title={title}>
            {title.slice(start, end)}
          </span>
          <b title={title}>{title.slice(end, title.length)}</b>
        </span>
      );
    }
    return title;
  };

  const flattenRightDataResult = flattenData(innerleftD, innerData);
  const flattenLeftDataResult = flattenDataLeft(innerleftD, innerData);

  useEffect(() => {
    // eslint-disable-next-line no-param-reassign
    downloadState.current = {
      left: flattenLeftDataResult,
      right: flattenRightDataResult,
    };
  }, [flattenLeftDataResult, flattenRightDataResult]);
  // рекурсивный поиск разворачиваемого элемент внутри дерева айтемов таргета
  // добавление развернутых айтемов во вьюшку таргета
  // добавление paddingScale к каждому новому уровню дерева для отрисовки древовидной труктуры
  const recursionToggle = (item: TLeftD, el: TLeftD): TLeftD => {
    if (item?.key === el.key) {
      return {
        ...item,
        isToggle: !el.isToggle,
      };
    }
    if (item?.children) {
      return {
        ...item,
        children: item.children.map((child) => recursionToggle(child, el)),
      };
    }
    return item;
  };

  const handleIsToggle = useCallback(
    (el: TLeftD) => {
      setInnerleftD((d) => d.map((item) => recursionToggle(item, el)));
      setLeftD((d) => d.map((item) => recursionToggle(item, el)));
    },
    [innerleftD],
  );

  const hScroll = useRef<HTMLDivElement>(null);
  const vScroll = useRef<HTMLDivElement>(null);
  const vScrollL = useRef<HTMLDivElement>(null);
  const header = useRef<HTMLDivElement>(null);
  const [stop, setStop] = useState<number>(0);

  const targetColumnLeft = createRef<FixedSizeList>();
  const targetColumnData = createRef<FixedSizeList>();

  const listRef = useRef<HTMLDivElement>();

  const scrollSync = useCallback(
    (e: Event) => {
      if (!hScroll.current) return;
      if (e.target instanceof Element) {
        hScroll.current.scrollLeft = e.target.scrollLeft;
        setStop(e.target.scrollTop);
      }
    },
    [hScroll],
  );
  const scrollSyncL = useCallback(
    (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
      if (!vScrollL.current) return;
      if (e.target instanceof Element && type === 'source' && isEmpty) {
        setStop(e.target.scrollTop);
      }
    },
    [vScrollL, type, isEmpty],
  );

  useEffect(() => {
    listRef.current?.addEventListener('scroll', scrollSync);
    return () => {
      listRef.current?.removeEventListener('scroll', scrollSync);
    };
  }, [listRef.current]);

  useEffect(() => {
    if (!vScrollL.current) return;
    if (!vScroll.current) return;

    vScrollL.current.scrollTop = stop;
    vScroll.current.scrollTop = stop;
  }, [stop, vScrollL, vScroll]);

  const RenderRow: FC<ListChildComponentProps<Tdata[]>> = React.useCallback(
    // eslint-disable-next-line no-shadow
    ({ index, style, data }) => {
      const row = data[index];
      return (
        <>
          <div
            className={cn(css.column, {
              isGroup: !!row?.isChild || false,
              isChilds: !!row?.isChild || false,
              isTotal: !!row?.children?.length || false,
              isGrey: index % 2 === 0,
            })}
            key={row?.key}
            style={{
              ...style,
              justifyContent:
                columns.length <= 3 ? 'flex-start' : 'space-between',
            }}
          >
            {columns.map((cell) =>
              read_only && cell.title === 'BIDRATE'
                ? ''
                : hideFieldsByRoleAndTitle(
                    isOwner,
                    cell.title,
                    fieldsToHide,
                  ) && (
                    <div
                      key={cell.dataIndex + cell.path}
                      className={cn(css.cell, colClass[cell.dataIndex], {
                        _isGrey:
                          cell.title.trim().toLocaleLowerCase() === 'bid',
                        // _isCenter: colClass[cell.dataIndex],
                      })}
                      onChange={(e) => {
                        const cursorPosition = e.target.selectionStart;
                        requestAnimationFrame(() => {
                          e.target.setSelectionRange(
                            cursorPosition,
                            cursorPosition,
                          );
                        });
                        setTouchedItemsToSave((prev) => [...prev, row.key]);
                      }}
                    >
                      {/* eslint-disable-next-line no-nested-ternary */}
                      {cell.cell
                        ? cell.cell(row)
                        : // eslint-disable-next-line no-nested-ternary
                        cell.dataIndex === 'key'
                        ? row?.[cell.dataIndex]
                        : cell.path
                        ? checkLimitsValue(row, cell.path)
                        : numFormat(i18next.language, row?.[cell.dataIndex])}
                    </div>
                  ),
            )}
          </div>
        </>
      );
    },
    [columns],
  );

  const calcWidth = useMemo(() => {
    if (columns.length > 3) {
      return '100%';
    }
    const widthColumns = columns.reduce(
      (sum, current) => sum + current.title.length,
      0,
    );

    return `${columns.length * 60 + widthColumns * 9}px`;
  }, [columns]);

  const onTouched = (row: TLeftD) => {
    setTouchedItemsToSave((prev) => [...prev, row.key]);
  };

  const onChecked = useCallback(
    (k: string, v: boolean, row?: TLeftD) => () => {
      if (row) {
        onTouched(row);
      }
      if (!use_settings || view === 1) return;
      onChekedLD(k, v);
    },
    [onChekedLD, use_settings],
  );

  const onRemove = useCallback(
    (k: string) => () => {
      onRemoveItem(k);
    },
    [onRemoveItem],
  );
  // Функция рекурсивного пуша item.is_checked для функции ниже
  const recursionPushPartiallyValues = (item: TLeftD, resultArr: boolean[]) => {
    if (item.children && item.children.length) {
      item.children.forEach((child) =>
        recursionPushPartiallyValues(child, resultArr),
      );
      return;
    }
    resultArr.push(item.is_checked);
  };
  // Функция пушит все нижележащие в дереве флаги чека(item.is_checked) в один массив и определяет состояние родительского чекбокса
  // Учитываются только состояния чилдрен айтемов максимальной вложенности(не имеют своих children)
  const checkPartially = (item: TLeftD) => {
    const resultArr: boolean[] = [];
    if (item?.children && !statisticsIsGroup) {
      item.children.forEach((child) =>
        recursionPushPartiallyValues(child, resultArr),
      );
    } else {
      resultArr.push(item?.is_checked);
    }
    if (
      resultArr.some((item1) => item1 === true) &&
      resultArr.some((item2) => item2 === false)
    ) {
      return 'partially';
    }
    if (resultArr.every((item3) => item3 === true)) {
      return 'allCheck';
    }
    return 'noCheck';
  };

  interface IGroupCheckboxHandler {
    item: TLeftD;
    parentPartiallyVal?: boolean;
    mode?: string;
  }
  // Функция устанавливает чекбоксы дочерних элементов в положение согласно статутсу родительского чекбокса на который кликнули
  const RecursiveCheckboxHandler = ({
    item,
    parentPartiallyVal,
  }: IGroupCheckboxHandler): TLeftD => {
    if (item.children && item.children.length && !statisticsIsGroup) {
      let mappedChildrenArr: TLeftD[] = [];
      if (parentPartiallyVal) {
        mappedChildrenArr = item.children.map((child) =>
          RecursiveCheckboxHandler({
            item: child,
            parentPartiallyVal,
          }),
        );
        return { ...item, children: mappedChildrenArr };
      }
      mappedChildrenArr = item.children.map((child) =>
        RecursiveCheckboxHandler({ item: child }),
      );
      return { ...item, children: mappedChildrenArr };
    }
    if (parentPartiallyVal) {
      return item.is_checked
        ? {
            ...item,
            is_checked: false,
            _isSettings: true,
          }
        : item;
    }
    return {
      ...item,
      is_checked: true,
      _isSettings: true,
    };
  };

  const groupCheckboxHandler = ({
    item,
    parentPartiallyVal,
  }: IGroupCheckboxHandler) => {
    setTouchedItemsToSave((prev) => [...prev, item.key]);
    if (item.children && item.children.length && !statisticsIsGroup) {
      if (isSourceLikeLogic) {
        onChekedLD(item.key, !(checkPartially(item) === 'allCheck'));
        return;
      }
      if (parentPartiallyVal) {
        item.children.forEach((child) =>
          groupCheckboxHandler({
            item: child,
            parentPartiallyVal,
          }),
        );
        return;
      }
      item.children.forEach((child) => groupCheckboxHandler({ item: child }));
      return;
    }
    if (parentPartiallyVal) {
      onChekedLD(item.key, false);
      return;
    }
    onChekedLD(item.key, true);
  };

  // Функция для главного чекбокса, изменяет значение чекбоксов для всех итемов таргета
  const onCheckAll = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      if (!use_settings || view === 1) return;
      setShowSaveButton(true);
      if (search) {
        /*         const innerKeys = innerleftD.map((innerItem) => innerItem.key);
        setLeftD((prev) =>
          prev.map((item) =>
            innerKeys.includes(item.key)
              ? RecursiveCheckboxHandler({
                  item,
                  parentPartiallyVal: allCheck || anyCheck,
                })
              : item,
          ),
        ); */
        innerleftD.forEach((item) =>
          groupCheckboxHandler({
            item,
            parentPartiallyVal: allCheck || anyCheck,
          }),
        );
        return;
      }
      setLeftD((prev) =>
        prev.map((item) =>
          RecursiveCheckboxHandler({
            item,
            parentPartiallyVal: allCheck || anyCheck,
          }),
        ),
      );
      leftD.forEach((ld) => setTouchedItemsToSave((prev) => [...prev, ld.key]));
    },
    [use_settings, search, allCheck, anyCheck, innerleftD],
  );

  /* Рассчет отступов слева для каждого итема при построении древовидной структуры */
  const calcPadding = (row: TLeftD) => {
    if (statisticsIsGroup) {
      if (row.children) {
        return `${(row.paddingScale - 1) * 22}px`;
      }
      return `26px`;
    }
    if (row.children && !!row.children?.length) {
      return `${(row.paddingScale - 1) * 22}px`;
    }
    return `${(row.paddingScale - 1) * 22 + 22}px`;
  };

  const calcWidthGridItem = (row: TLeftD) => `calc(100% - 10px - ${
    // eslint-disable-next-line no-nested-ternary
    statisticsIsGroup ? (row.children ? 78 : 48) : row.paddingScale * 22 + 30
  }px
    ${type === 'source' ? '- 52px' : ''}`;

  const RenderLeftRow: FC<ListChildComponentProps<TLeftD[]>> =
    React.useCallback(
      // eslint-disable-next-line no-shadow
      ({ index, style, data }) => {
        const row = data[index];
        if (!row) return <></>;
        // рендер древовидных итемов
        if (row?.children && row.children.length && !statisticsIsGroup) {
          return (
            <div
              style={{
                ...style,
                background: index % 2 === 0 ? '#F9F9F9' : '',
              }}
            >
              <div
                className={css.item}
                style={{
                  paddingLeft: calcPadding(row),
                }}
              >
                {row.children && !!row.children.length && (
                  <div
                    className={css.action}
                    onClick={() => handleIsToggle(row)}
                  >
                    <ReactSVG
                      className={cn(css.img, { active: row.isToggle })}
                      src={ArrowSvg}
                      size={18}
                    />
                  </div>
                )}
                <div
                  className={cn(css.checkboxWrap, {
                    end: !row.children,
                    toggleNone: row.children && !row.children.length,
                  })}
                  style={{
                    width: calcWidthGridItem(row),
                  }}
                  onClick={() => {
                    if (use_settings || isLocked) return;
                    showHintEvent();
                  }}
                >
                  <Checkbox
                    isPartiallyChecked={checkPartially(row) === 'partially'}
                    search={search}
                    label={row.title}
                    inputAttributes={{
                      type: 'checkbox',
                      checked: checkPartially(row) === 'allCheck',
                      onChange: () =>
                        groupCheckboxHandler({
                          item: row,
                          parentPartiallyVal:
                            checkPartially(row) === 'partially' ||
                            checkPartially(row) === 'allCheck',
                        }),
                    }}
                    disabled={!use_settings || isLocked}
                  />
                </div>
                {isSourceLike && isOwner && (
                  <ItemBidType
                    typeVal={row.type}
                    itemKey={row.key}
                    onChangeCellData={onChangeCellData}
                    showHintEvent={showHintEvent}
                    disabled={!use_settings}
                    setTouchedItemsToSave={setTouchedItemsToSave}
                  />
                )}
              </div>
            </div>
          );
        }
        return (
          <>
            <div
              className={cn(css.cell, {
                isVideoType: statisticsIsGroup,
                isGroup: row?.isChild,
                isChilds: row?.isChild,
              })}
              key={row.key}
              style={{
                ...style,
                paddingLeft: calcPadding(row),
                background: index % 2 === 0 ? '#F9F9F9' : '',
              }}
            >
              {statisticsIsGroup && (
                <>
                  {row.children && (
                    <div
                      className={cn(css.action, {
                        _noList: !row.children,
                      })}
                      onClick={() => handleIsToggle(row)}
                    >
                      <div className={css.arrow}>
                        <ReactSVG
                          className={cn(css.img, { active: row.isToggle })}
                          src={ArrowSvg}
                          size={18}
                        />
                      </div>
                    </div>
                  )}
                </>
              )}
              {row.isChild ? (
                <ReactSVG className={css.icon} src={PointSvg} size={8} />
              ) : (
                <div
                  className={cn('checkbox', {
                    isChecked: row.is_checked,
                    isDisabled: !use_settings || view === 1 || isLocked,
                  })}
                  onClick={
                    // eslint-disable-next-line no-nested-ternary
                    !isLocked
                      ? use_settings
                        ? onChecked(row.key, !row.is_checked, row)
                        : () => showHintEvent()
                      : () => null
                  }
                />
              )}
              <span
                style={{
                  width: calcWidthGridItem(row),
                }}
              >
                <div className={css.flex}>
                  <div className={css.title}>
                    {searchLabel(row.title, search)}
                  </div>
                  {type && contextTarget.includes(type) && (
                    <OnlyOwner>
                      {!use_settings ? (
                        <ReactSVG
                          className="icon"
                          src={CopySvg}
                          size={8}
                          onClick={
                            use_settings
                              ? onChangeContextBox(row)
                              : onShowContextBox(row)
                          }
                        />
                      ) : (
                        <ReactSVG
                          className="icon"
                          src={EditSvg}
                          size={8}
                          onClick={
                            use_settings
                              ? onChangeContextBox(row)
                              : onShowContextBox(row)
                          }
                        />
                      )}
                    </OnlyOwner>
                  )}
                  {(type === 'page_context' ||
                    (type === 'app' &&
                      !row.isChild &&
                      !Number.isNaN(+row.title))) && (
                    <OnlyOwner>
                      <Tooltip
                        hideHint
                        bottom={index === 0}
                        onClick={() => {
                          if (type === 'app') {
                            showPageAppRow(row.title);
                          } else {
                            showPageContextRow(row.title);
                          }
                        }}
                        title={<ReactSVG src={CopySvg} size={8} />}
                      />
                    </OnlyOwner>
                  )}
                </div>
              </span>
              {checkItemLimits(row.limits) && (
                <div
                  className={cn(css.circle, 'limits_circle', {
                    isDisabled: !use_settings,
                  })}
                />
              )}
              {type && isSourceLike && isOwner && (
                <ItemBidType
                  typeVal={row.type}
                  itemKey={row.key}
                  disabled={!use_settings}
                  onChangeCellData={onChangeCellData}
                  showHintEvent={showHintEvent}
                  setTouchedItemsToSave={setTouchedItemsToSave}
                />
              )}
              {![1, 5].includes(view) && (
                <ItemActions
                  className={css.actions}
                  onRemove={view !== 5 ? onRemove(row.key) : null}
                  disabled={!use_settings && isLocked}
                  currentItemKey={row.key}
                  currentItemTitle={row.title}
                  setSomeIsOpen={setSomeIsOpen}
                />
              )}
            </div>
          </>
        );
      },
      [use_settings, search, expand, scrollCalc],
    );

  return (
    <>
      <div
        className={cn(css.grid, {
          isExtended: isThemeExtended || isThemeFull,
        })}
      >
        <div className={css.header} ref={header}>
          <div className={css.fixed} style={{ width: `${expand}px` }}>
            <SortHeader
              columnKey="title"
              className={css.cell}
              title={
                <>
                  <div
                    className={cn('checkbox', {
                      isChildChecked: anyCheck,
                      isChecked: allCheck && !!flattenLeftDataResult.length,
                      isDisabled: !use_settings || view === 1 || isLocked,
                    })}
                    onClick={(e) => {
                      if (isLocked) return;
                      onCheckAll(e);
                      showHintEvent();
                    }}
                  />
                  <span>{i18next.t('targetings.table.name')}</span>
                </>
              }
              expand={expand}
              setExpand={onSetExpand}
              isShowExpand
              notSort={false}
              sortType={sortType}
              setSortType={onSetSortType}
            />
            {total && (
              <SortHeader
                className={css.cell}
                style={style_sortHeader}
                title={
                  <span className={css.total_title}>
                    {i18next.t('targetings.total')}
                  </span>
                }
                expand={expand}
                setExpand={() => null}
                notSort
                setSortType={() => null}
              />
            )}
          </div>

          <div
            className={cn(css.scroll, {
              _isNoRaw: !flattenLeftDataResult.length,
            })}
            ref={hScroll}
            style={{ width: `calc(100% - ${expand + 8}px)` }}
          >
            <div
              style={{
                justifyContent:
                  columns.length <= 3 ? 'flex-start' : 'space-between',
              }}
            >
              {columns.map((h) =>
                read_only && h.title === 'BIDRATE'
                  ? ''
                  : hideFieldsByRoleAndTitle(
                      isOwner,
                      h.title,
                      fieldsToHide,
                    ) && (
                      <SortHeader
                        columnKey={h.dataIndex}
                        className={css.cell}
                        title={h.title}
                        expand={expand}
                        setExpand={onSetExpand}
                        setSortType={onSetSortType}
                        sortType={sortType}
                        key={h.dataIndex + h.path}
                        notSort={h.notSort ?? false}
                        additionalClass={colClass[h.dataIndex]}
                      />
                    ),
              )}
            </div>
            <div>
              {total &&
                columns.map((val, index) =>
                  read_only && index === 0
                    ? ''
                    : hideFieldsByRoleAndTitle(
                        isOwner,
                        val.title,
                        fieldsToHide,
                      ) && (
                        <div
                          className={cn(css.cell, colClass[val.dataIndex], {
                            _isGrey:
                              val.title.trim().toLocaleLowerCase() === 'bid',
                          })}
                          key={val.dataIndex + val.path}
                        >
                          {numFormat(
                            i18next.language,
                            total?.[val.dataIndex],
                            '',
                          )}
                        </div>
                      ),
                )}
            </div>
          </div>
        </div>
        <div
          className={cn(
            css.body,
            isEmpty && type !== 'source' ? '_isEmpty' : '',
          )}
          onScroll={scrollSyncL}
        >
          {!isError && (
            <>
              <div>
                <FixedSizeList
                  className={cn(css.fixed, {
                    _noBorder: flattenLeftDataResult.length < 5,
                  })}
                  height={
                    flattenLeftDataResult.length >= MIN_ROWS
                      ? TABLE_H
                      : flattenLeftDataResult.length * ROW_H
                  }
                  itemCount={flattenLeftDataResult.length}
                  itemSize={ROW_H}
                  itemData={flattenLeftDataResult}
                  overscanCount={10}
                  layout="vertical"
                  width="auto"
                  style={{
                    width: `${expand}px`,
                    willChange: 'initial',
                    overflow: 'overlay',
                  }}
                  itemKey={itemKeyLeft}
                  ref={targetColumnLeft}
                  onScroll={({ scrollOffset }) => {
                    targetColumnData.current?.scrollTo(scrollOffset);
                  }}
                >
                  {RenderLeftRow}
                </FixedSizeList>
              </div>
              <div
                className={cn(css.content, {
                  _isFull: flattenLeftDataResult.length < 5,
                })}
                style={{
                  width: `calc(100% - ${expand}px)`,
                  overflow: 'hidden',
                }}
                onClick={showHintEvent}
              >
                <FixedSizeList
                  className={css.content}
                  height={
                    flattenRightDataResult.length >= MIN_ROWS
                      ? TABLE_H + 8
                      : flattenRightDataResult.length * ROW_H + 13
                  }
                  itemCount={flattenRightDataResult.length}
                  overscanCount={10}
                  itemSize={ROW_H}
                  itemData={flattenRightDataResult}
                  style={{
                    willChange: 'initial',
                    overflow: 'scroll',
                  }}
                  layout="vertical"
                  width={calcWidth}
                  itemKey={itemKeyData}
                  outerRef={listRef}
                  ref={targetColumnData}
                  onScroll={({ scrollOffset }) => {
                    if (someIsOpen) {
                      setSomeIsOpen(false);
                      throttledScrollCalc(scrollOffset);
                    }
                    targetColumnLeft.current?.scrollTo(scrollOffset);
                  }}
                >
                  {RenderRow}
                </FixedSizeList>
                {columns.length <= 3 && (
                  <div className={css.empty}>
                    <div
                      className={cn(css.body, {
                        _isSmall: flattenLeftDataResult.length < 5,
                      })}
                    >
                      <span>
                        {!isError && (
                          <>
                            <ReactSVG src={statOffSvg} className="icon" />
                            {!showStatistic &&
                              i18next.t('targetings.statistic_off')}
                            {showStatistic &&
                              (view === 3 || view === 4
                                ? i18next.t('targetings.no_statistic')
                                : i18next.t('targetings.no_data'))}
                          </>
                        )}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
          {isError && (
            <div className={css.statistics_error}>
              <div className={css.statistics_error_icon}>
                <FiCloudLightning size={28} color="#828282" />
              </div>
              {i18next.t('targetings.error_statistics')}
              <div
                className={css.statistics_error_btn}
                onClick={() => fetchStatistics()}
              >
                {i18next.t('update_btn')}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default DataGrid;
