import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DateRangeFilter from 'components/DateRangeFilter';
import HeaderActions from 'components/HeaderActions';
import Button from 'components/UI/Buttons/buttons';
import Breadcrumbs from 'components/Breadcrumbs/breadcrumbs';
import { parsingDate } from 'utils/momentParse';
import SwitchItem from 'components/UI/SwitchItem';
import { FiExternalLink, FiRefreshCcw } from 'react-icons/fi';
import { useCreativeTypes } from 'domains/creative/model/selectors';
import { checkedLocalization } from 'utils/checkedLocalization';
import InternalId from 'components/InternalId';
import { useSwitchNavigation } from 'hooks/useSwitchNavigation';
import { RUseSearchField } from 'hooks/useSearchField';
import Load from 'components/UI/Load';
import TooltipPoratal from 'components/UI/TooltipPoratal';
import { everyGroups } from 'utils/statics/everyGroups';
import { useDspLinks } from 'utils/useDspLinks/useDspLinks';
import Dropdown from './Dropdown';
import css from './styles.module.scss';
import { useCreativesHeader } from '../hooks/useCreativesHeader';
import AccessUserRight from '../../../../components/AccessUserRight';
import { useOnScroll } from '../../../../hooks/useOnScroll';
import { useCreativeDownloadReportStatisticsList } from '../../model/hooks/useCreativeDownloadReportStatisticsList';
import DownloadButton from '../../../../components/UI/Buttons/DownloadButton';
import { useUserInfo, useUserTheme } from '../../../user/model/selectors';

interface IProps {
  isThemeExtended: boolean;
  isThemeFull: boolean;
  searchMatch: number;
  searchRef: React.RefObject<HTMLInputElement>;
  searchObj: RUseSearchField;
  isShowMatch: boolean;
  currentTranslationXxhashArr: string[];
}

const Header: React.FC<IProps> = ({
  isThemeExtended,
  isThemeFull,
  searchMatch,
  searchRef,
  searchObj,
  isShowMatch,
  currentTranslationXxhashArr,
}) => {
  const { data: user } = useUserInfo();
  const { onChangeSearch, search, onCloseSearch, onOpenSearch, toggleSearch } =
    searchObj;
  const { getJsonCampaignDspLink } = useDspLinks();
  const {
    active,
    onSetActive,
    onUpdateStatistic,
    period,
    type,
    campaign,
    match,
  } = useCreativesHeader({ search, currentTranslationXxhashArr });

  const { data: creativeTypes } = useCreativeTypes();

  const { getValueFromSettingForKey } = useUserTheme();

  const {
    prevLink,
    nextLink,
    isLoading: isLoadingNavigation,
    showNavigation,
    count,
    isDisableLink,
  } = useSwitchNavigation({ mode: 'campaign' });

  const { t } = useTranslation();

  const { fixed } = useOnScroll(80);

  const { handleDownload, isDisabled } =
    useCreativeDownloadReportStatisticsList({
      period,
      campaign,
      search,
    });

  if (!campaign) {
    return (
      <div
        className={cn(css.placeholder, {
          isSmall: isThemeFull,
          _isfixed: fixed,
          _isActive: active,
        })}
      >
        <div
          className={cn(css.header, { isActive: active }, { isFixed: fixed })}
        >
          <Load
            style={{
              width: '100%',
              height: '100%',
            }}
          />
        </div>
      </div>
    );
  }

  return (
    <div
      className={cn(css.placeholder, {
        isSmall: isThemeFull,
        _isfixed: fixed,
        _isActive: active,
      })}
    >
      <div className={cn(css.header, { isActive: active }, { isFixed: fixed })}>
        <div
          className={cn('container resized', {
            extend: isThemeExtended,
            full: isThemeFull,
          })}
        >
          {campaign && (
            <Breadcrumbs
              type="Campaign"
              xxhash={campaign.xxhash}
              style={{ opacity: toggleSearch ? 0 : 1 }}
            />
          )}
          <div className={cn(css.status, { isToggleSearch: toggleSearch })}>
            {campaign && (
              <h1 className={css.title}>
                {campaign.internal_id && (
                  <InternalId internal_id={campaign.internal_id} />
                )}
                <span title={campaign.title}>{campaign.title}</span>
                {everyGroups(user, 'test') && campaign && (
                  <a
                    href={getJsonCampaignDspLink()}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <TooltipPoratal hint="get json dsp">
                      <FiExternalLink size={16} />
                    </TooltipPoratal>
                  </a>
                )}
              </h1>
            )}
            <HeaderActions
              toggleSearch={toggleSearch}
              onOpenSearch={onOpenSearch}
              searchMatch={searchMatch}
              search={search}
              onChangeSearch={onChangeSearch}
              inputRef={searchRef}
              onCloseSearch={onCloseSearch}
              disabled={isDisabled}
              isShowMatch={isShowMatch}
            >
              <div
                className={css.update}
                onClick={() => onUpdateStatistic(period, type)}
              >
                <TooltipPoratal hint={t(`update_btn`)}>
                  <FiRefreshCcw size={22} />
                </TooltipPoratal>
              </div>
              <DownloadButton onClick={handleDownload} disabled={isDisabled} />
              <div className={css.range}>
                <DateRangeFilter
                  range={period}
                  dateStart={parsingDate(campaign?.date_start.date).toDate()}
                  dateEnd={parsingDate(campaign?.date_end.date).toDate()}
                  onSetDateRange={onUpdateStatistic}
                  initialTitleDay={type}
                  onSetActive={onSetActive}
                  disabled={isDisabled}
                />
              </div>
              {(getValueFromSettingForKey('HideNavigationCampaign') ||
                getValueFromSettingForKey('HideNavigationCampaign') ===
                  null) && (
                <SwitchItem
                  count={count}
                  showNavigation={showNavigation}
                  prevLink={prevLink}
                  nextLink={nextLink}
                  isLoading={isLoadingNavigation}
                  isDisableLink={isDisableLink}
                  onCloseSearch={onCloseSearch}
                />
              )}
              <AccessUserRight>
                {(props) => (
                  <Dropdown
                    isDisable={props.isDisable}
                    header={
                      <Button
                        title={t('creative.create_btn')}
                        height={40}
                        buttonAttributes={{
                          disabled: props.isDisable,
                        }}
                      />
                    }
                    onSetActive={onSetActive}
                  >
                    {creativeTypes?.map(({ key, icon }) => (
                      <Link
                        key={key}
                        to={{
                          pathname: `/main/campaign/${match.params.id}/creative-add`,
                          state: {
                            type: key,
                          },
                        }}
                      >
                        {icon === '' ? (
                          <div className={css.plag_img} />
                        ) : (
                          <img src={icon} alt="icon" />
                        )}
                        <span>
                          {checkedLocalization(
                            `creative.create_menu.${key}`,
                            key,
                          )}
                        </span>
                      </Link>
                    ))}
                  </Dropdown>
                )}
              </AccessUserRight>
            </HeaderActions>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
