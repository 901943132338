import React, { FC } from 'react';
import cn from 'classnames';
import { useUserTheme } from 'domains/user/model/selectors';
import i18n from '../../../../../../i18n';
import css from '../styles.module.scss';
import Card from '../../../../../../components/UI/Card';
import Help from '../../../../../../components/Help';
import Checkbox from '../../../../../../components/UI/Checkbox/checkbox';
import InputAutoSave from '../../../../../../components/UI/InputAutoSave';
import { isFieldInObject } from '../../../../../../utils/isFieldInObject';
import { CampaignGeneralComponentProps, RGetPersonal } from '../../../../types';

type ContainerProps = Pick<
  CampaignGeneralComponentProps,
  'formik' | 'autoSubmit' | 'savedField' | 'handlerInput' | 'setField'
> & {
  campaign: RGetPersonal | null;
};

const Container: FC<ContainerProps> = ({
  formik,
  setField,
  handlerInput,
  savedField,
  campaign,
}) => {
  const { getValueFromSettingForKey } = useUserTheme();
  return (
    <Card
      noHover
      style={{ padding: '28px 24px' }}
      className={css.card}
      isUseAccess
    >
      <h3>
        {i18n.t('campaigns_page.campaign_settings.Container')}
        {getValueFromSettingForKey('HideHelp') && (
          <Help
            width={150}
            title={i18n.t('campaigns_page.campaign_settings.Container')}
            helpattributes={{
              link: i18n.t(
                'campaigns_page.campaign_settings.campaign_prompt.container.link',
              ),
              sizeIcon: 18,
            }}
          />
        )}
      </h3>
      <div className={css.form}>
        <div className={css.row}>
          <InputAutoSave
            defaultValue={campaign?.container.cid}
            value={formik.values.container.cid}
            name="container.cid"
            setFieldValue={formik.setFieldValue}
            isError={!!formik.errors.container?.cid}
            handlerInputEnter={handlerInput}
            errorText={i18n.t(formik.errors.container?.cid as string)}
            isSaved={isFieldInObject(savedField, 'container.cid')}
            label={i18n.t('campaigns_page.campaign_settings.container_block.cid')}
          />
        </div>
        <div className={css.additionl}>
          <div className={css.additionl__label}>
            {i18n.t(
              'campaigns_page.campaign_settings.container_block.additionally',
            )}
          </div>
          <Checkbox
            label={i18n.t(
              'campaigns_page.campaign_settings.container_block.save',
            )}
            inputAttributes={{
              name: 'container.save_strk_to_cookie',
              type: 'checkbox',
              checked: !!formik.values.container.save_strk_to_cookie,
              onChange: ({ target }) => {
                setField(
                  'container.save_strk_to_cookie',
                  !target.checked ? 0 : 1,
                );
              },
            }}
          />
        </div>
      </div>
    </Card>
  )
};
export default Container;
